import { Stack } from '@mui/material';
import { AppTypography } from '../../../components/base/AppTypography';
import { DatePartDuration } from '../../../components/common/dates/DatesPartDuration';
import { ResumeProjectProps } from '../../../elf/types/ResumeProjectTypes';
import { Bulletpoint } from '../../projects/common/BulletPoint';

export function ResumeProject(project: ResumeProjectProps) {
    return (
        <Stack>
            <Stack justifyContent="space-between" direction="row">
                <Stack>
                    <AppTypography variant="h3">
                        <u>{project.title}</u> - {project.type}
                    </AppTypography>
                    {project.description && (
                        <AppTypography>{project.description}</AppTypography>
                    )}
                </Stack>
                <Stack alignItems="end">
                    {project.dates.dates.map((date) => (
                        <DatePartDuration
                            key={date.startFormatted}
                            start={date.startFormatted}
                            end={date.endFormatted}
                            typographyProps={{ fontStyle: 'italic' }}
                        />
                    ))}
                </Stack>
            </Stack>
            <Stack direction="row" marginTop={2} spacing={2}>
                <Stack direction="column" spacing={1}>
                    {project.summary.map((text, i) => (
                        <Bulletpoint key={i}>{text}</Bulletpoint>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
}
