import { Stack } from '@mui/material';
import { AppTypography } from '../../components/base/AppTypography';

export function ResumeObjective() {
    return (
        <Stack spacing={1}>
            <AppTypography
                sx={{ textDecoration: 'underline' }}
                paddingTop={2}
                variant="h3"
            >
                Summary
            </AppTypography>
            <AppTypography variant="body1">
                Proficient in Java, SQL, React, and Python, with a proven track
                record of optimizing databases, implementing modular codebases,
                and developing full-stack applications. Successfully managed a
                database of over 500 million rows in Cloverbot, reducing storage
                by 60%.
            </AppTypography>
        </Stack>
    );
}
